import { REQP } from '../../services/config'

console.log(REQP)

import { UserService } from "@/services";
import PubSub from "@/lib/Pub";
import Cluse from "@/components/sign/Cluse.vue";

export default {
	name: "reg",
	components: {
		Cluse
	},
	data() {
		return {
			showCluse: false,
			formData: {
				name: "",
				mobile: "",
				password: "",
				veriCode: "",
				password2: "",
				agree: false
			},
			vaildateCode: "",
			rules: {
				name: [{ required: true, message: "必填", trigger: "blur" }],
				mobile: [
					{ required: true, message: "必填", trigger: "change" },
					//					{
					//						type: "mobile",
					//						message: "请输入正确的手机号码",
					//						trigger: ["blur", "change"]
					//					}
				],
				veriCode: [
					{ required: true, message: "必填", trigger: "change" },
					//					{
					//						validator: this.validateCheckCode,
					//						message: "验证码不正确",
					//						trigger: "blur"
					//					}
				],
				password: [
					{ required: true, message: "请输入密码", trigger: "blur" },
					{ min: 6, max: 20, message: "密码长度必须是6-20位", trigger: "blur" }
				],
				password2: [
					{
						required: true,
						message: "请再次输入密码",
						trigger: ["change", "blur"]
					},
					{ validator: this.validatePassword, trigger: "blur" }
				],
				agree: []
			}
		};
	},
	methods: {
		login: function () {
			PubSub.publish("showLoginEvent");
		},

		onSendVeriSms() {
			this.$refs["formData"].validateField("mobile", async e => {
				if (e) {
					this.$message("请输入正确的手机号码");
					return;
				}
				let { mobile } = this.formData;
				this.postReq(REQP.smsVeri, { mobile }, res => {
					this.vaildateCode = res;
				})
			});
		},

		validatePassword(rule, value, callback) {
			if (value !== this.formData.password) {
				callback(new Error("两次输入密码不一致!"));
			} else {
				callback();
			}
		},

		validateCheckCode(rule, value, callback) {
			if (value !== this.vaildateCode) {
				callback(new Error("验证码不正确"));
			} else {
				callback();
			}
		},

		submitForm(formData) {
			console.log(REQP)
			this.$refs[formData].validate(async valid => {
				if (!valid) return
				let { agree, password2, ...data } = this.formData;
				this.postReq(REQP.regUser, data, data => {
					PubSub.publish("LoginSuccessEvent");
					this.$router.push("/");
				})
			});
		}
	}
};